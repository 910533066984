<script setup lang="ts">
const localePath = useLocalePath()

const sitedata: any = useSiteData()
</script>

<template>
  <div v-if="sitedata?.layout !== 'singlepage' && sitedata?.hascart" class="fixed bottom-20 right-2 print:hidden">
    <UButton
      title="Cart"
      aria-label="cart-button-float"
      icon="i-heroicons-shopping-cart-solid"
      size="xl"
      square
      variant="solid"
      :ui="{ rounded: 'rounded-full' }"
      :style="`background-color: ${sitedata?.primary_color ?? '#00701a'}`"
      @click="navigateTo(localePath('/cart'))"
    />
  </div>
</template>
